@import "_variables.scss";

.badge-path-header-container {
  padding: var(--chakra-space-12);
  border-bottom: 1px solid $color-gradient-light-orange;
  box-shadow: $box-shadow-default; 
  width: 100%;
  display: flex;
  flex-direction: column;
  background: url("../../../../../../public/assets/images/header-banner-bg-academy.png") no-repeat;
    background-size: cover;
    background-position: -200px;

  .main-title {
    font-family: $font-family-sfmono;
    font-size: $font-size-xs;
    font-weight: $font-weight-medium; 
    line-height: $line-height-small; 
    letter-spacing: 0em;
    text-align: left;
    z-index: 2;
  }

  .page-title {
    font-family: $font-family-regular;
    font-size: $font-size-xl;
    font-weight: $font-weight-600;
    line-height: var(--chakra-space-16);
    text-align: left;
    display: flex;
    gap: var(--chakra-space-2);
    align-items: center;
    padding-top: var(--chakra-space-2);
    z-index: 2;
    .back-btn {
      background: none;
      padding: 0;
    }
  }

  .page-subtitle {
    font-family: $font-family-regular;
    font-size: $font-size-med;
    font-weight: $font-weight-400;
    line-height: calc(var(--chakra-space-9) + 1.5px);
    letter-spacing: 0em;
    text-align: left;
    padding-top: var(--chakra-space-2);
    z-index: 2;
  }

  .progress-box {
    display: flex;
    flex-direction: column;
    gap: var(--chakra-space-2);
    padding: var(--chakra-space-6) 0;
    width: 50%;
    z-index: 2;

    .progress-box-time, .progress-box-value {
      display: flex;
      align-items: center;
      font-size: $font-size-reg;
      font-weight: $font-weight-400;
      line-height: calc(var(--chakra-space-9) + 1.5px);
      text-align: left;
    }

    .progress-box-value {
      font-size: $font-size-reg;
      font-weight: $font-weight-400;
      line-height: calc(var(--chakra-space-9) + 1.5px);
      text-align: left;
    }

    .progress-box-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.min-height-badge-progress{
  min-height: calc(var(--chakra-space-60) + var(--chakra-space-12));
}

.min-height-no-badge-progress{
  min-height: var(--chakra-space-60);
}

.tooltip-back-text{
  display: flex;
  align-items: center;
  height: calc(var(--chakra-space-12) + 2px);
  border-radius: calc(var(--chakra-space-3) + 0.5px);
  font-size: calc(var(--chakra-space-4) + 2px);
  font-weight: $font-weight-600;
}
